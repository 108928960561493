.container
  background-image: url(./bg.jpg)
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 10vh
  padding-bottom: 15vh
  justify-content: space-between
  text-align: center
  padding-left: 5vw
  padding-right: 5vw
  background-size: cover
  background-position: center
  color: #333
  @media screen and (min-width: 1000px)
    background-image: none
    background: #FFC728

  h1
    font-size: 1.5em
    font-weight: 700
    max-width: 400px
    margin-bottom: 100px

    @media screen and (min-width: 1000px)
      max-width: 800px
      margin-bottom: 0

  .img
    max-width: 400px
    width: 85%

  .imgdesk
    display: none
    @media screen and (min-width: 1000px)
      display: block
      max-width: 300px

  .btn
    background-color: white
    border: none
    padding-left: 4em
    padding-right: 4em
    border: 1px solid #ffd95c
    border-radius: 20px
    cursor: pointer
    font-size: 16px
    line-height: 58px
    font-weight: 700
    text-transform: uppercase
    letter-spacing: 0.03em
    color: #ffffff
    background: #ef3328



.listItem
  display: flex
  align-items: center
  text-align: left
  padding-left: 8px
  padding-right: 8px

  &:not(:first-child)
    margin-top: 16px
  
  svg
    flex-shrink: 0
    margin-right: 12px