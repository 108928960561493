.overlay
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: transparent
  transition: background-color 0.2s
  pointer-events: none
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden

  &.active
    background-color: rgba(0, 0, 0, 0.5)
    pointer-events: all

.dialog
  min-height: 20vh
  width: 90vw
  max-width: 400px
  background-color: white
  border-radius: 10px
  align-items: stretch
  display: flex
  flex-direction: column
  padding: 16px
  text-align: center
