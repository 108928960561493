@import ui
@import components

body
  margin: 0
  padding: 0
  
html, body, input, button, textarea
  font-family: 'Noto Sans', sans-serif
  font-size: 14px

*
  box-sizing: border-box