.btn
  position: absolute
  top: 16px
  right: 16px
  background: white
  width: 44px
  height: 44px
  border: none
  border-radius: 9999px
  background-size: cover
  background-position: center
  z-index: 10
  
div.dialog 
  background-color: #FFC62A
  text-align: left
  z-index: 100

div.videoDialog
  padding: 0
  position: relative
  min-height: 10vh
  max-width: 600px
  width: 96vw
  background-color: black
  video
    width: 100%
    position: static
    height: auto
    border-radius: 10px

.disclaimer
  opacity: 0.4
  background: #fff
  text-align: center
  width: 100%
  max-width: 100%
  position: fixed
  bottom: 0
  color: #333
  font-family: inherit
  line-height: 20px
  font-weight: 400
  font-size: 15px
  text-transform: uppercase
  letter-spacing: 0.035em
  padding: 10px 0

  span
    display: block
    max-width: 370px
    margin: auto