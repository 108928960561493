.h
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  overflow: hidden

.flex
  display: flex
  align-items: center

.stack
  display: flex
  flex-direction: column
  align-items: center

.anim-wrapper
  align-self: stretch
  display: flex
  flex-direction: column
  align-items: center
  &>*
    position: absolute

  @media screen and (min-width: 1000px)
      display: none